import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { appVersion } from "utils/params";

import { TMAP } from "constants/links";

export const MapContext = createContext({});

const CONSTANTS = {
  SET_MAP: "SET_MAP",
  SET_CURRENT_POSITION: "SET_CURRENT_POSITION",
  SET_CURRENT_CENTER: "SET_CURRENT_CENTER",
  SET_MAP_BOUNDS: "SET_MAP_BOUNDS",
  SET_SHOW_HIDE_MARKERS: "SET_SHOW_HIDE_MARKERS",
  SET_SHOW_MARKER_ADDRESS: "SET_SHOW_MARKER_ADDRESS",
  SET_LINK_OTHERS: "SET_LINK_OTHERS",
};

const INITIAL_STATE = {
  map: null,
  mapBounds: null,
  currentPosition: null,
  currentCenter: true,
  showHideMarkers: true,
  showMarkerAddress: "ALL",
  linkOthers: TMAP,
};

const CURRENT_POSITION_INIT = "CURRENT_POSITION_INIT";

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.SET_MAP:
      return {
        ...state,
        map: action.map,
      };
    case CONSTANTS.SET_MAP_BOUNDS:
      return {
        ...state,
        mapBounds: action.mapBounds,
      };
    case CONSTANTS.SET_CURRENT_POSITION:
      return {
        ...state,
        currentPosition: action.currentPosition,
      };
    case CONSTANTS.SET_CURRENT_CENTER:
      return {
        ...state,
        currentCenter: action.currentCenter,
      };
    case CONSTANTS.SET_SHOW_HIDE_MARKERS:
      return {
        ...state,
        showHideMarkers: action.showHideMarkers,
      };
    case CONSTANTS.SET_SHOW_MARKER_ADDRESS:
      return {
        ...state,
        showMarkerAddress: action.showMarkerAddress,
      };
    case CONSTANTS.SET_LINK_OTHERS:
      return {
        ...state,
        linkOthers: action.linkOthers,
      };
    default:
      return INITIAL_STATE;
  }
};

export const MapProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <MapContext.Provider value={{ state, dispatch }}>
      {children}
    </MapContext.Provider>
  );
};

export const useMapStore = () => {
  const { dispatch, state } = useContext(MapContext);

  const mapRef = useRef(null);
  const currentMarkerRef = useRef(null);
  const currentCenterRef = useRef(true);
  const currentCenterTimeRef = useRef(0);

  useEffect(() => {
    getCurrentCenterOnStorage();
    getHideMarkersOnStorage();
    getMakerAddressOnStorage();
    getLinkOthersOnStorage();
  }, []);

  const getCurrentCenterOnStorage = useCallback(async () => {
    const _currentCenter = await localStorage.getItem("currentCenter");
    if (_currentCenter) {
      currentCenterRef.current = JSON.parse(_currentCenter);
      dispatch({
        type: CONSTANTS.SET_CURRENT_CENTER,
        currentCenter: JSON.parse(_currentCenter),
      });
    }
  }, [dispatch]);

  const getHideMarkersOnStorage = useCallback(async () => {
    const _showHideMarkers = await localStorage.getItem("showHideMarkers");
    if (_showHideMarkers) {
      dispatch({
        type: CONSTANTS.SET_SHOW_HIDE_MARKERS,
        showHideMarkers: JSON.parse(_showHideMarkers),
      });
    }
  }, [dispatch]);

  const getMakerAddressOnStorage = useCallback(async () => {
    const _showMarkerAddress = await localStorage.getItem("showMarkerAddress");
    if (_showMarkerAddress) {
      if (_showMarkerAddress === "true") {
        dispatch({
          type: CONSTANTS.SET_SHOW_MARKER_ADDRESS,
          showMarkerAddress: "ALL",
        });
      } else if (_showMarkerAddress === "false") {
        dispatch({
          type: CONSTANTS.SET_SHOW_MARKER_ADDRESS,
          showMarkerAddress: "NONE",
        });
      } else {
        dispatch({
          type: CONSTANTS.SET_SHOW_MARKER_ADDRESS,
          showMarkerAddress: _showMarkerAddress,
        });
      }
    }
  }, [dispatch]);

  const getLinkOthersOnStorage = useCallback(async () => {
    const _linkOthers = await localStorage.getItem("linkOthers");
    if (_linkOthers) {
      dispatch({
        type: CONSTANTS.SET_LINK_OTHERS,
        linkOthers: _linkOthers,
      });
    }
  }, [dispatch]);

  const drawMap = useCallback(
    ({ zoom }) => {
      mapRef.current = new window.naver.maps.Map("map", {
        zoom: zoom || 15,
      });
      dispatch({ type: CONSTANTS.SET_MAP, map: mapRef.current });
    },
    [dispatch],
  );

  const getCurrentPosition = useCallback(
    (_currentCenter) => {
      if (state.currentPosition) {
        if (!state.currentCenter) {
          setCurrentPostionMarker(state.currentPosition, CURRENT_POSITION_INIT);
        }
      } else {
        if (window.ReactNativeWebView) {
          if (appVersion) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ type: "currentPosition" }),
            );
          } else {
            window.ReactNativeWebView.postMessage("currentPosition");
          }
        }
      }

      localStorage.setItem("currentCenter", _currentCenter);
      currentCenterRef.current = JSON.parse(_currentCenter);
      dispatch({
        type: CONSTANTS.SET_CURRENT_CENTER,
        currentCenter: JSON.parse(_currentCenter),
      });
    },
    [
      dispatch,
      state.currentCenter,
      state.currentPosition,
      currentCenterRef.current,
      appVersion,
    ],
  );

  const updateCurrentPostion = useCallback(
    (position) => {
      if (currentMarkerRef.current) {
        setCurrentPostionMarker(position);
      } else {
        setCurrentPostionMarker(position, CURRENT_POSITION_INIT);
      }
    },
    [currentMarkerRef.current],
  );

  const showCurrentMaker = useCallback(() => {
    currentMarkerRef.current.setMap(mapRef.current);
  }, [currentMarkerRef.current]);

  const setCurrentPostionMarker = useCallback(
    (position, type) => {
      if (!mapRef.current) {
        mapRef.current = state.map;
      }

      dispatch({
        type: CONSTANTS.SET_CURRENT_POSITION,
        currentPosition: position,
      });

      if (
        currentCenterRef.current &&
        new Date().getTime() - currentCenterTimeRef.current > 5000
      ) {
        currentCenterTimeRef.current = new Date().getTime();
        mapRef.current.setCenter(
          new window.naver.maps.LatLng(position.latitude, position.longitude),
        );
      }

      if (currentCenterRef.current && type === CURRENT_POSITION_INIT) {
        mapRef.current.setOptions({
          zoom: 18,
        });
      }

      if (currentMarkerRef.current) {
        currentMarkerRef.current.setPosition(
          new window.naver.maps.LatLng(position.latitude, position.longitude),
        );

        currentMarkerRef.current.setIcon({
          content: [
            "<div>",
            `<img style="transform: rotate( ${
              position.heading || 0
            }deg ); width: 20px;" src="/assets/images/map/marker-current-heading.png" />`,
            "</div>",
          ].join(""),
          size: new window.naver.maps.Size(20, 20),
          scaledSize: new window.naver.maps.Size(20, 20),
        });
      } else {
        currentMarkerRef.current = new window.naver.maps.Marker({
          map: mapRef.current,
          position: new window.naver.maps.LatLng(
            position.latitude,
            position.longitude,
          ),
          title: "Current",
          icon: {
            content: [
              "<div>",
              `<img style="transform: rotate( ${
                position.heading || 0
              }deg ); width: 20px;" src="/assets/images/map/marker-current-heading.png" />`,
              "</div>",
            ].join(""),
            size: new window.naver.maps.Size(20, 20),
            scaledSize: new window.naver.maps.Size(20, 20),
          },
          zIndex: 100,
        });

        showCurrentMaker();
      }
    },
    [
      dispatch,
      state.map,
      currentMarkerRef.current,
      currentCenterRef.current,
      currentCenterTimeRef.current,
      mapRef.current,
    ],
  );

  const setMapCenter = useCallback(
    ({ latitude, longitude }) => {
      if (!mapRef.current) {
        mapRef.current = state.map;
      }

      if (mapRef.current) {
        mapRef.current.setCenter(
          new window.naver.maps.LatLng(latitude, longitude),
        );
      }
    },
    [state.map, mapRef.current],
  );

  const setMapCurrentCenter = useCallback(() => {
    if (!mapRef.current) {
      mapRef.current = state.map;
    }

    if (mapRef.current && state.currentPosition) {
      mapRef.current.setCenter(
        new window.naver.maps.LatLng(
          state.currentPosition.latitude,
          state.currentPosition.longitude,
        ),
      );
    }
  }, [state.map, state.currentPosition, mapRef.current]);

  const setShowHideMarkers = useCallback(
    (showHideMarkers) => {
      localStorage.setItem("showHideMarkers", showHideMarkers);

      dispatch({
        type: CONSTANTS.SET_SHOW_HIDE_MARKERS,
        showHideMarkers,
      });
    },
    [dispatch],
  );

  const setShowMarkerAddress = useCallback(
    (showMarkerAddress) => {
      localStorage.setItem("showMarkerAddress", showMarkerAddress);

      dispatch({
        type: CONSTANTS.SET_SHOW_MARKER_ADDRESS,
        showMarkerAddress,
      });
    },
    [dispatch],
  );

  const setLinkOthers = useCallback(
    (linkOthers) => {
      localStorage.setItem("linkOthers", linkOthers);
      dispatch({
        type: CONSTANTS.SET_LINK_OTHERS,
        linkOthers,
      });
    },
    [dispatch],
  );

  return {
    state,
    drawMap,
    getCurrentPosition,
    updateCurrentPostion,
    setMapCenter,
    setMapCurrentCenter,
    setShowHideMarkers,
    setShowMarkerAddress,
    setLinkOthers,
  };
};
