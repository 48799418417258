import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import {
  BaseAddressesProvider,
  CommonProvider,
  DeliveriesProvider,
  DeliveryProvider,
  MapProvider,
  ModalProvider,
  OrderProvider,
  RidersProvider,
  RoutesProvider,
  ReallocationProvider,
} from "./stores";

import Router from "./Router";

import "bootstrap/dist/css/bootstrap.min.css";

const GlobalStyle = createGlobalStyle`
  body {
    font-size: 0;
  }
  
  *, :after, :before {
    box-sizing: border-box;
  }

  .c-red {
    color: #ea2845;
  }

  .bg-red {
    background-color: #ea2845;
    color: #fff;
  }

  // marker
  .marker {
    color: #202020;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 14px;
    position: relative;
    padding-top: 4px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 40px;
    background-image: url(/assets/images/map/marker-deliver.png);
    
    &.completed {
      background-image: url(/assets/images/map/marker-completed.png);
    }

    &.completed-route {
      background-image: url(/assets/images/map/marker-completed-route.png);
    }

    &.soojione {
      background-image: url(/assets/images/map/marker-completed-route.png);
    }

    &.deliver {
      background-image: url(/assets/images/map/marker-deliver.png);
    }

    &.pickup {
      background-image: url(/assets/images/map/marker-pickup.png);
    }

    &.pickupPlace {
      background-image: url(/assets/images/map/marker-pickup-place.png);
    }

    &.deliverWaiting {
      background-image: url(/assets/images/map/marker-waiting.png);
    }

    &.deliverPostponed {
      background-image: url(/assets/images/map/marker-postponed.png);
    }

    &.cs {
      background-image: url(/assets/images/map/marker-cs.png);
    }

    &.deliverPenalty {
      background-image: url(/assets/images/map/marker-deliver-penalty.png);
    }

    &.deliver.limitedDeliveryHours, 
    &.deliverPenalty.limitedDeliveryHours {
      background-image: url(/assets/images/map/marker-limited-delivery-hours.png);
    }

  }

  .marker-plus-wrap {
    position: absolute;
    top: 0;
    left: 26px;
    z-index: 10;
    display: flex;
    align-items: center;
    min-height: 19px;
  }

  .marker-plus {
    height: 19px;
    border-radius: 10px;
    background-color: #202020;
    color: #fff;
    line-height: 1;
    padding: 2px 7px;
    margin-right: 2px;
  }

  .marker-near {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -4px;

    &.delivery {
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
    
    &.deliveryPickup {
      background-color: #FC3F00; // orange
    }    

    &.deliveryWait {
      background-color: #FFA800; // yellow
    }
    
    &.deliveryStart {
      background-color: #14B6C1; // mint
    }

    &.deliveryPostponed {
      background-color: #4350B6; // deepblue
    }

    &.cs {
      background-color: #000000; // black
    }
  }

  .marker-icons {
    position: absolute;
    left: 25px;
    z-index: 10;
    top: 11px;
    display: flex;

    &.plus {
      top: 20px;
    }
  }

  .marker-icon {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: #fff;

    &.highPrice {
      background-color: #EBFF00; // neonyellow
    }

    &.time {
      background-color: #0BFF00; // neongreen
    }

    &.rearrange {
      background-color: #C357F5; // purple
    }

    &.deliveryWait {
      background-color: #FFA800; // yellow
    }
    
    &.deliveryStart {
      background-color: #14B6C1; // mint
    }

    &.deliveryPostponed {
      background-color: #4350B6; // deepblue
    }
    
    &.cs {
      background-color: #000000; // black
    }
  }

  // tooltip
  .info {
    &.deliver {
      border: 2px solid rgba(128, 128, 128, 0);
    }

    &.deliver.limitedDeliveryHours {
      border: 2px solid #2bd723;
    }

    &.deliver.highPrice {
      border: 2px solid #ea2845;
    }
  }

  .info-icons {
    display: flex;
    flex-direction: row;

    .info-icon {
      width: 14px;
      height: 14px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
      font-size: 10px;
      color: #fff;

      &.highPrice {
        background-color: #EBFF00; // neonyellow
      }
  
      &.time {
        background-color: #0BFF00; // neongreen
      }
  
      &.rearrange {
        background-color: #C357F5; // purple
      }
  
      &.deliveryWait {
        background-color: #FFA800; // yellow
      }
      
      &.deliveryStart {
        background-color: #14B6C1; // mint
      }
  
      &.deliveryPostponed {
        background-color: #4350B6; // deepblue
      }

      &.cs {
        background-color: #000000; // deepblue
      }
      
      &.disabled {
        background-color: #dedede;
      }
    }
  }

  // modal
  .modal-backdrop {
    background-color: #000;
  }

  .modal {
    .modal-dialog {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      width: 100%;
      min-width: 100%;
      margin: 0;
      flex-direction: column;
    }
  
    .modal-content {
      width: 100vw;
      border-radius: 0;
      border: none;
      flex: none;
      position: relative;
      background-color: transparent;
    }
  }

  .modal.modal-center {
    .modal-dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      min-width: 100%;
      margin: 0;
      flex-direction: column;
    }

    .modal-content {
      width: 90vw;
      border-radius: 0;
      flex: none;
      padding: 0 10px;
      postion: relative;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      background-color: #fff;

    }

    .modal-lg {
      .modal-content {
        width: 800px;
      }
    }

    .modal-header {
      .close {
        padding: 13px;

        span {
          font-size: 26px;
        }
      }
    }

    .modal-body {
      max-height: calc(100vh - 200px);
      overflow: auto;
    }
  }
  
  // toast
  .toast-background {
    border: solid 1px #202020;
    background-color: #ffffff;
    width: 80vw;
    min-height: 0;
    height: auto;
    left: 10vw;
    top: 20px;
    border-radius: 20px;
    font-size: 0;
  }
  .toast-body {
    padding: 0 10px;
    font-size: 14px;
    color: #202020;
    text-align: center;
  }
  .fancy-progress-bar {
    opacity: 0 !important;
  }
`;

const AppProvider = ({ contexts, children }) =>
  contexts.reduce(
    (prev, context) =>
      React.createElement(context, {
        children: prev,
      }),
    children,
  );

const Root = () => {
  return (
    <AppProvider
      contexts={[
        BaseAddressesProvider,
        CommonProvider,
        DeliveriesProvider,
        DeliveryProvider,
        MapProvider,
        ModalProvider,
        OrderProvider,
        RidersProvider,
        RoutesProvider,
        ReallocationProvider,
      ]}
    >
      <GlobalStyle />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </AppProvider>
  );
};

export default Root;
