import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";

import {
  Cs,
  Delivery,
  STATUS_PICKUP,
  STATUS_PICKUP_NORMAL,
  STATUS_PICKUP_RETURN,
  STATUS_DELIVERY_WAITING,
  STATUS_DELIVERY_POSTPONED,
  STATUS_DELIVERY_STARTED,
  STATUS_DELIVERY_ALLOCATED,
  STATUS_CS,
  STATUS_COMPLETED,
  STATUS_PICKUP_FOR_COUNT,
  STATUS_DELIVERY_ALLOCATED_FOR_COUNT,
  STATUS_DELIVERY_WAITING_FOR_COUNT,
  STATUS_DELIVERY_POSTPONED_FOR_COUNT,
  STATUS_DELIVERY_STARTED_FOR_COUNT,
  STATUS_DELIVERED_FOR_COUNT,
  DELIVERY_TYPE_NORMAL,
  DELIVERY_TYPE_PICKUP,
  DELIVERY_TYPE_RETURN,
  DELIVERY_TYPE_PICKUP_RETURN,
  DELIVERY_STATUS_TYPES,
  ALLOCATION_MATCH_TYPES,
} from "Models";

import api from "services/Api";

import { reOrder } from "utils/common";
import { riderTokenNew } from "utils/params";
import { yymmddWithDash, addDays } from "utils/date";

export const DeliveriesContext = createContext({});

const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  FETCH_ALLOCATED: "FETCH_ALLOCATED",
  FETCH_COUNT: "FETCH_COUNT",
  FETCH_COMPLETED: "FETCH_COMPLETED",
  SET_STATUSES: "SET_STATUSES",
  SET_LIMITED_HOURS_COUNT: "SET_LIMITED_HOURS_COUNT",
};

const INITIAL_STATE = {
  statuses: [
    STATUS_PICKUP_NORMAL,
    STATUS_PICKUP_RETURN,
    STATUS_DELIVERY_WAITING,
    STATUS_DELIVERY_POSTPONED,
    STATUS_DELIVERY_STARTED,
    STATUS_CS,
  ],
  list: [], // 전체 목록 (배송/수거 포함)
  markerList: [], // 마커목록
  markerListWithSameplace: [], // 마커목록(동일 위치 포함)

  deliveryStartList: [], // 배송 출발 목록
  orderList: [], // 순서지정 목록

  deliveryCompleted: null, // 최근 배송 완료 건

  fetched: false,

  deliveriesAllocated: [], // 배송대기/출발 목록

  count: {
    pickups: 0,
    pickupsNormal: 0,
    pickupsReturn: 0,
    deliveriesAllocated: 0,
    deliveriesBeforeStart: 0,
    deliveriesPostponed: 0,
    deliveriesStarted: 0,
    delivered: 0,
    cses: 0,
  },

  limitedHoursCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        list: action.list,
        markerList: action.markerList,
        markerListWithSameplace: action.markerListWithSameplace,
        deliveryStartList: action.deliveryStartList,
        orderList: action.orderList,
        fetched: true,
      };
    case CONSTANTS.FETCH_ALLOCATED:
      return {
        ...state,
        deliveriesAllocated: action.deliveriesAllocated,
      };
    case CONSTANTS.FETCH_COUNT:
      return {
        ...state,
        count: {
          ...state.count,
          ...action.count,
        },
      };
    case CONSTANTS.FETCH_COMPLETED:
      return {
        ...state,
        deliveryCompleted: action.deliveryCompleted,
      };
    case CONSTANTS.SET_STATUSES:
      return {
        ...state,
        statuses: action.statuses,
      };
    case CONSTANTS.SET_LIMITED_HOURS_COUNT:
      return {
        ...state,
        limitedHoursCount: action.limitedHoursCount,
      };

    default:
      return INITIAL_STATE;
  }
};

export const DeliveriesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <DeliveriesContext.Provider value={{ state, dispatch }}>
      {children}
    </DeliveriesContext.Provider>
  );
};

export const useDeliveriesStore = () => {
  const { dispatch, state } = useContext(DeliveriesContext);

  useEffect(() => {
    getStatusesOnStorage();
  }, []);

  const getStatusesOnStorage = useCallback(async () => {
    const _statuses = await localStorage.getItem("statuses");
    if (_statuses) {
      dispatch({
        type: CONSTANTS.SET_STATUSES,
        statuses: JSON.parse(_statuses),
      });
    }
  }, [dispatch]);

  const getSamePlaces = (item, deliveries) => {
    if (item.status === STATUS_PICKUP) {
      return deliveries.filter(
        (el) =>
          ((el.senderAddressRoad === item.senderAddressRoad &&
            ((el.compareSenderLocation.longitude -
              item.compareSenderLocation.longitude >=
              0 &&
              el.compareSenderLocation.longitude -
                item.compareSenderLocation.longitude <=
                0.00005) ||
              (item.compareSenderLocation.longitude -
                el.compareSenderLocation.longitude >=
                0 &&
                item.compareSenderLocation.longitude -
                  el.compareSenderLocation.longitude <=
                  0.00005)) &&
            ((el.compareSenderLocation.latitude -
              item.compareSenderLocation.latitude >=
              0 &&
              el.compareSenderLocation.latitude -
                item.compareSenderLocation.latitude <=
                0.00005) ||
              (item.compareSenderLocation.latitude -
                el.compareSenderLocation.latitude >=
                0 &&
                item.compareSenderLocation.latitude -
                  el.compareSenderLocation.latitude <=
                  0.00005))) ||
            (el.compareSenderLocation.longitude ===
              item.compareSenderLocation.longitude &&
              el.compareSenderLocation.latitude ===
                item.compareSenderLocation.latitude)) &&
          el.bookId !== item.bookId &&
          el.status === item.status,
      );
    } else {
      return deliveries.filter(
        (el) =>
          ((el.receiverAddressRoad === item.receiverAddressRoad &&
            ((el.compareReceiverLocation.longitude -
              item.compareReceiverLocation.longitude >=
              0 &&
              el.compareReceiverLocation.longitude -
                item.compareReceiverLocation.longitude <=
                0.00005) ||
              (item.compareReceiverLocation.longitude -
                el.compareReceiverLocation.longitude >=
                0 &&
                item.compareReceiverLocation.longitude -
                  el.compareReceiverLocation.longitude <=
                  0.00005)) &&
            ((el.compareReceiverLocation.latitude -
              item.compareReceiverLocation.latitude >=
              0 &&
              el.compareReceiverLocation.latitude -
                item.compareReceiverLocation.latitude <=
                0.00005) ||
              (item.compareReceiverLocation.latitude -
                el.compareReceiverLocation.latitude >=
                0 &&
                item.compareReceiverLocation.latitude -
                  el.compareReceiverLocation.latitude <=
                  0.00005))) ||
            (el.compareReceiverLocation.longitude ===
              item.compareReceiverLocation.longitude &&
              el.compareReceiverLocation.latitude ===
                item.compareReceiverLocation.latitude)) &&
          el.bookId !== item.bookId &&
          el.status === item.status,
      );
    }
  };

  const getSamePlacesForCs = (item, deliveriesCs) => {
    return deliveriesCs.filter(
      (el) =>
        ((el.deliveryForMarker?.compareReceiverLocation.longitude ===
          item.deliveryForMarker?.compareReceiverLocation.longitude &&
          el.deliveryForMarker?.compareReceiverLocation.latitude ===
            item.deliveryForMarker?.compareReceiverLocation.latitude) ||
          el.deliveryForMarker.receiverAddressRoad ===
            item.deliveryForMarker.receiverAddressRoad) &&
        el.bookId !== item.bookId,
    );
  };

  const getPickupSamePlaces = (item, deliveries) => {
    return deliveries.filter(
      (el) =>
        ((el.senderAddressRoad === item.receiverAddressRoad &&
          ((el.compareSenderLocation.longitude -
            item.compareReceiverLocation.longitude >=
            0 &&
            el.compareSenderLocation.longitude -
              item.compareReceiverLocation.longitude <=
              0.00005) ||
            (item.compareReceiverLocation.longitude -
              el.compareSenderLocation.longitude >=
              0 &&
              item.compareReceiverLocation.longitude -
                el.compareSenderLocation.longitude <=
                0.00005)) &&
          ((el.compareSenderLocation.latitude -
            item.compareReceiverLocation.latitude >=
            0 &&
            el.compareSenderLocation.latitude -
              item.compareReceiverLocation.latitude <=
              0.00005) ||
            (item.compareReceiverLocation.latitude -
              el.compareSenderLocation.latitude >=
              0 &&
              item.compareReceiverLocation.latitude -
                el.compareSenderLocation.latitude <=
                0.00005))) ||
          (el.compareSenderLocation.longitude ===
            item.compareReceiverLocation.longitude &&
            el.compareSenderLocation.latitude ===
              item.compareReceiverLocation.latitude)) &&
        el.bookId !== item.bookId &&
        el.status === STATUS_PICKUP,
    );
  };

  const getPickupSamePlacesForCs = (item, deliveries) => {
    return deliveries.filter(
      (el) =>
        ((el.senderAddressRoad ===
          item.deliveryForMarker?.receiverAddressRoad &&
          ((el.compareSenderLocation.longitude -
            item.deliveryForMarker?.compareReceiverLocation.longitude >=
            0 &&
            el.compareSenderLocation.longitude -
              item.deliveryForMarker?.compareReceiverLocation.longitude <=
              0.00005) ||
            (item.deliveryForMarker?.compareReceiverLocation.longitude -
              el.compareSenderLocation.longitude >=
              0 &&
              item.deliveryForMarker?.compareReceiverLocation.longitude -
                el.compareSenderLocation.longitude <=
                0.00005)) &&
          ((el.compareSenderLocation.latitude -
            item.deliveryForMarker?.compareReceiverLocation.latitude >=
            0 &&
            el.compareSenderLocation.latitude -
              item.deliveryForMarker?.compareReceiverLocation.latitude <=
              0.00005) ||
            (item.deliveryForMarker?.compareReceiverLocation.latitude -
              el.compareSenderLocation.latitude >=
              0 &&
              item.deliveryForMarker?.compareReceiverLocation.latitude -
                el.compareSenderLocation.latitude <=
                0.00005))) ||
          (el.compareSenderLocation.longitude ===
            item.deliveryForMarker?.compareReceiverLocation.longitude &&
            el.compareSenderLocation.latitude ===
              item.deliveryForMarker?.compareReceiverLocation.latitude)) &&
        el.bookId !== item.deliveryForMarker?.bookId &&
        el.status === STATUS_PICKUP,
    );
  };

  const getDeliverySamePlacesForCs = (item, deliveries) => {
    return deliveries.filter(
      (el) =>
        ((el.receiverAddressRoad ===
          item.deliveryForMarker?.receiverAddressRoad &&
          ((el.compareReceiverLocation.longitude -
            item.deliveryForMarker?.compareReceiverLocation.longitude >=
            0 &&
            el.compareReceiverLocation.longitude -
              item.deliveryForMarker?.compareReceiverLocation.longitude <=
              0.00005) ||
            (item.deliveryForMarker?.compareReceiverLocation.longitude -
              el.compareReceiverLocation.longitude >=
              0 &&
              item.deliveryForMarker?.compareReceiverLocation.longitude -
                el.compareReceiverLocation.longitude <=
                0.00005)) &&
          ((el.compareReceiverLocation.latitude -
            item.deliveryForMarker?.compareReceiverLocation.latitude >=
            0 &&
            el.compareReceiverLocation.latitude -
              item.deliveryForMarker?.compareReceiverLocation.latitude <=
              0.00005) ||
            (item.deliveryForMarker?.compareReceiverLocation.latitude -
              el.compareReceiverLocation.latitude >=
              0 &&
              item.deliveryForMarker?.compareReceiverLocation.latitude -
                el.compareReceiverLocation.latitude <=
                0.00005))) ||
          (el.compareReceiverLocation.longitude ===
            item.deliveryForMarker?.compareReceiverLocation.longitude &&
            el.compareReceiverLocation.latitude ===
              item.deliveryForMarker?.compareReceiverLocation.latitude)) &&
        el.bookId !== item.deliveryForMarker?.bookId &&
        (el.status === STATUS_DELIVERY_STARTED ||
          el.status === STATUS_DELIVERY_WAITING ||
          el.status === STATUS_DELIVERY_POSTPONED),
    );
  };

  /**
   * 배송목록 정제
   */
  const setDeliveries = useCallback(
    (statuses, deliveries, deliveriesCs) => {
      let _deliveries = deliveries
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .sort((a, b) => (a.isPenaltyCorpUser < b.isPenaltyCorpUser ? 1 : -1));

      let _orderList = [];
      let _deliveryStartList = [];
      let _markerList = [];

      deliveriesCs.forEach((item) => {
        let deliveryForMarker = item.deliveryForMarker;
        if (deliveryForMarker) {
          deliveryForMarker.statusText = "cs";
          deliveryForMarker.longitude =
            deliveryForMarker.compareReceiverLocation.longitude;
          deliveryForMarker.latitude =
            deliveryForMarker.compareReceiverLocation.latitude;

          const samePlaces = getSamePlacesForCs(item, deliveriesCs);
          const pickupSamePlaces = getPickupSamePlacesForCs(item, _deliveries);
          const deliverySamePlaces = getDeliverySamePlacesForCs(
            item,
            _deliveries,
          );

          if (pickupSamePlaces.length > 0) {
            deliveryForMarker.samePlacesPickupCount = pickupSamePlaces.length;
            deliveryForMarker.samePlacesPickup = pickupSamePlaces;
          }

          if (deliverySamePlaces.length > 0) {
            deliveryForMarker.samePlacesDeliveryCount =
              deliverySamePlaces.length;
            deliveryForMarker.samePlacesDelivery = deliverySamePlaces;
          }

          if (samePlaces.length > 0) {
            deliveryForMarker.samePlacesCount = samePlaces.length;
            deliveryForMarker.samePlaces = samePlaces.map(
              (d) => new Delivery(d.delivery || {}, null, true),
            );

            if (
              _markerList.find(
                (el) =>
                  ((el.compareReceiverLocation.longitude ===
                    deliveryForMarker.longitude &&
                    el.compareReceiverLocation.latitude ===
                      deliveryForMarker.latitude) ||
                    el.receiverAddress === deliveryForMarker.receiverAddress) &&
                  el.statusText === "cs",
              )
            ) {
            } else {
              _markerList.push(deliveryForMarker);
            }
          } else {
            _markerList.push(deliveryForMarker);
          }
        }
      });

      _deliveries.forEach((item) => {
        if (
          item.status === STATUS_PICKUP ||
          (statuses.includes("PICKUP_NORMAL") &&
            item.statusAddressNotSupported === STATUS_PICKUP)
        ) {
          // 수거
          item.statusText = "pickup";
          item.longitude = item.compareSenderLocation.longitude;
          item.latitude = item.compareSenderLocation.latitude;

          const samePlaces = getSamePlaces(item, _deliveries);

          if (samePlaces.length > 0) {
            item.samePlacesCount = samePlaces.length;
            item.samePlaces = samePlaces;

            if (
              _markerList.find(
                (el) =>
                  ((el.compareSenderLocation.longitude === item.longitude &&
                    el.compareSenderLocation.latitude === item.latitude) ||
                    el.senderAddress === item.senderAddress) &&
                  (el.status === STATUS_PICKUP ||
                    el.statusAddressNotSupported === STATUS_PICKUP),
              )
            ) {
            } else {
              _markerList.push(item);
            }
          } else {
            _markerList.push(item);
          }
        } else if (
          item.status === STATUS_DELIVERY_STARTED ||
          (statuses.includes("DELIVERY_STARTED") &&
            item.statusAddressNotSupported === STATUS_DELIVERY_STARTED)
        ) {
          // 배송
          item.statusText = "deliver";
          item.longitude = item.compareReceiverLocation.longitude;
          item.latitude = item.compareReceiverLocation.latitude;

          if (item.order) {
            _orderList.push({
              bookId: item.bookId,
              order: item.order,
            });
          }

          const samePlaces = getSamePlaces(item, _deliveries);
          const pickupSamePlaces = getPickupSamePlaces(item, _deliveries);

          if (pickupSamePlaces.length > 0) {
            item.samePlacesPickupCount = pickupSamePlaces.length;
            item.samePlacesPickup = pickupSamePlaces;
          }
          if (samePlaces.length > 0) {
            item.samePlacesCount = samePlaces.length;
            item.samePlaces = samePlaces;

            const samePlacesRed = samePlaces.find(
              (el) => el.keyColor === "highPrice",
            );
            const samePlacesGreen = samePlaces.find(
              (el) => el.keyColor === "limitedDeliveryHours",
            );

            if (samePlacesGreen) {
              item.samePlacekeyColor = "limitedDeliveryHours";
            } else if (
              samePlacesRed &&
              item.keyColor !== "limitedDeliveryHours"
            ) {
              item.samePlacekeyColor = "highPrice";
            }

            if (
              _markerList.find(
                (el) =>
                  el.receiverAddress === item.receiverAddress &&
                  ((el.compareReceiverLocation.longitude - item.longitude >=
                    0 &&
                    el.compareReceiverLocation.longitude - item.longitude <=
                      0.00005) ||
                    (item.longitude - el.compareReceiverLocation.longitude >=
                      0 &&
                      item.longitude - el.compareReceiverLocation.longitude <=
                        0.00005)) &&
                  ((el.compareReceiverLocation.latitude - item.latitude >= 0 &&
                    el.compareReceiverLocation.latitude - item.latitude <=
                      0.00005) ||
                    (item.latitude - el.compareReceiverLocation.latitude >= 0 &&
                      item.latitude - el.compareReceiverLocation.latitude <=
                        0.00005)) &&
                  (el.status === STATUS_DELIVERY_STARTED ||
                    el.statusAddressNotSupported === STATUS_DELIVERY_STARTED),
              )
            ) {
            } else {
              _markerList.push(item);
            }
          } else {
            _markerList.push(item);
          }

          if (item.status === STATUS_DELIVERY_STARTED) {
            _deliveryStartList.push(item);
          }
        } else if (item.status === STATUS_DELIVERY_WAITING) {
          // 배송 대기
          item.statusText = "deliverWaiting";
          item.longitude = item.compareReceiverLocation.longitude;
          item.latitude = item.compareReceiverLocation.latitude;

          const samePlaces = getSamePlaces(item, _deliveries);

          if (samePlaces.length > 0) {
            item.samePlacesCount = samePlaces.length;
            item.samePlaces = samePlaces;

            if (
              _markerList.find(
                (el) =>
                  el.receiverAddress === item.receiverAddress &&
                  ((el.compareReceiverLocation.longitude - item.longitude >=
                    0 &&
                    el.compareReceiverLocation.longitude - item.longitude <=
                      0.00005) ||
                    (item.longitude - el.compareReceiverLocation.longitude >=
                      0 &&
                      item.longitude - el.compareReceiverLocation.longitude <=
                        0.00005)) &&
                  ((el.compareReceiverLocation.latitude - item.latitude >= 0 &&
                    el.compareReceiverLocation.latitude - item.latitude <=
                      0.00005) ||
                    (item.latitude - el.compareReceiverLocation.latitude >= 0 &&
                      item.latitude - el.compareReceiverLocation.latitude <=
                        0.00005)) &&
                  el.status === STATUS_DELIVERY_WAITING,
              )
            ) {
            } else {
              _markerList.push(item);
            }
          } else {
            _markerList.push(item);
          }
        } else if (item.status === STATUS_DELIVERY_POSTPONED) {
          // 배송 연기
          item.statusText = "deliverPostponed";
          item.longitude = item.compareReceiverLocation.longitude;
          item.latitude = item.compareReceiverLocation.latitude;

          const samePlaces = getSamePlaces(item, _deliveries);

          if (samePlaces.length > 0) {
            item.samePlacesCount = samePlaces.length;
            item.samePlaces = samePlaces;

            if (
              _markerList.find(
                (el) =>
                  el.receiverAddress === item.receiverAddress &&
                  ((el.compareReceiverLocation.longitude - item.longitude >=
                    0 &&
                    el.compareReceiverLocation.longitude - item.longitude <=
                      0.00005) ||
                    (item.longitude - el.compareReceiverLocation.longitude >=
                      0 &&
                      item.longitude - el.compareReceiverLocation.longitude <=
                        0.00005)) &&
                  ((el.compareReceiverLocation.latitude - item.latitude >= 0 &&
                    el.compareReceiverLocation.latitude - item.latitude <=
                      0.00005) ||
                    (item.latitude - el.compareReceiverLocation.latitude >= 0 &&
                      item.latitude - el.compareReceiverLocation.latitude <=
                        0.00005)) &&
                  el.status === STATUS_DELIVERY_POSTPONED,
              )
            ) {
            } else {
              _markerList.push(item);
            }
          } else {
            _markerList.push(item);
          }
        }
      });

      let _resetOrder = reOrder(_orderList);

      let markerList = [];
      let markerListWithSameplace = [];

      _markerList.sort((a, b) =>
        a.compareReceiverLocation.latitude < b.compareReceiverLocation.latitude
          ? 1
          : -1,
      );

      _markerList.sort((a, b) =>
        a.statusMarkerOrder > b.statusMarkerOrder ? 1 : -1,
      );

      _markerList.forEach((marker) => {
        const prevMarkerIndex = markerList.findIndex(
          (d) =>
            d.bookId !== marker.bookId &&
            Number(d.latitude) === Number(marker.latitude) &&
            Number(d.longitude) === Number(marker.longitude) &&
            d.status !== marker.status,
        );

        if (prevMarkerIndex > -1) {
          markerList.splice(prevMarkerIndex, 1);
        }

        markerListWithSameplace.push(marker);
        markerList.push(marker);
      });

      dispatch({
        type: CONSTANTS.FETCH_ALL,
        list: _deliveries,
        orderList: _resetOrder,
        markerList: markerList,
        markerListWithSameplace: markerListWithSameplace,
        deliveryStartList: _deliveryStartList,
      });
    },
    [dispatch],
  );

  /**
   * 배송 목록
   */
  const fetchAll = useCallback(async () => {
    const _statuses = await localStorage.getItem("statuses");
    const statuses = JSON.parse(_statuses) || state.statuses;

    let typeNormalStatusesPickupRider = []; // type=1, 수거건
    let typeNormalStatusesDeliveryRider = []; // type=1, 배송건

    let typePickupStatusesPickupRider = []; // type=2, 수거건
    let typePickupStatusesDeliveryRider = []; // type=2, 배송건

    let typeReturnStatusesPickupRider = []; // type=3, 수거건
    let typeReturnStatusesDeliveryRider = []; // type=3, 배송건

    let containCses = false;

    statuses.forEach((status) => {
      switch (status) {
        case STATUS_PICKUP_NORMAL:
          typeNormalStatusesPickupRider.push(
            DELIVERY_STATUS_TYPES.PICKUP_SCHEDULED,
          );
          typeNormalStatusesPickupRider.push(
            DELIVERY_STATUS_TYPES.ADDRESS_NOT_SUPPORTED,
          );
          break;
        case STATUS_PICKUP_RETURN:
          typePickupStatusesPickupRider.push(
            DELIVERY_STATUS_TYPES.PICKUP_SCHEDULED,
          );
          typeReturnStatusesPickupRider.push(
            DELIVERY_STATUS_TYPES.PICKUP_SCHEDULED,
          );
          break;
        case STATUS_DELIVERY_POSTPONED:
          typeNormalStatusesDeliveryRider.push(
            DELIVERY_STATUS_TYPES.DELIVERY_POSTPONED,
          );
          typePickupStatusesDeliveryRider.push(
            DELIVERY_STATUS_TYPES.DELIVERY_POSTPONED,
          );
          typeReturnStatusesDeliveryRider.push(
            DELIVERY_STATUS_TYPES.DELIVERY_POSTPONED,
          );
          break;
        case STATUS_DELIVERY_WAITING:
          typeNormalStatusesDeliveryRider.push(
            DELIVERY_STATUS_TYPES.DELIVERY_RIDER_ALLOCATED,
          );
          typePickupStatusesDeliveryRider.push(
            DELIVERY_STATUS_TYPES.DELIVERY_RIDER_ALLOCATED,
          );
          typeReturnStatusesDeliveryRider.push(
            DELIVERY_STATUS_TYPES.DELIVERY_RIDER_ALLOCATED,
          );
          break;
        case STATUS_DELIVERY_STARTED:
          typeNormalStatusesDeliveryRider.push(
            DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
          );
          typePickupStatusesDeliveryRider.push(
            DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
          );
          typeReturnStatusesDeliveryRider.push(
            DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
          );
          typeNormalStatusesDeliveryRider.push(
            DELIVERY_STATUS_TYPES.ADDRESS_NOT_SUPPORTED,
          );
          typePickupStatusesDeliveryRider.push(
            DELIVERY_STATUS_TYPES.ADDRESS_NOT_SUPPORTED,
          );
          typeReturnStatusesDeliveryRider.push(
            DELIVERY_STATUS_TYPES.ADDRESS_NOT_SUPPORTED,
          );
          break;
        case STATUS_CS:
          containCses = true;
          break;
        default:
          break;
      }
    });

    let promises = [];

    // type=1, 수거건
    if (typeNormalStatusesPickupRider.length) {
      promises.push(
        api.get("deliveries/v2", riderTokenNew, {
          statuses: typeNormalStatusesPickupRider,
          type: DELIVERY_TYPE_NORMAL,
          allocatedType: ALLOCATION_MATCH_TYPES.PICKUP,
        }),
      );
    }

    // type=2, 수거건
    if (typePickupStatusesPickupRider.length) {
      promises.push(
        api.get("deliveries/v2", riderTokenNew, {
          statuses: typePickupStatusesPickupRider,
          type: DELIVERY_TYPE_PICKUP,
          allocatedType: ALLOCATION_MATCH_TYPES.PICKUP,
        }),
      );
    }

    // type=3, 수거건
    if (typeReturnStatusesPickupRider.length) {
      promises.push(
        api.get("deliveries/v2", riderTokenNew, {
          statuses: typeReturnStatusesPickupRider,
          type: DELIVERY_TYPE_RETURN,
          allocatedType: ALLOCATION_MATCH_TYPES.PICKUP,
        }),
      );
    }

    // type=1, 배송건
    if (typeNormalStatusesDeliveryRider.length) {
      promises.push(
        api.get("deliveries/v2", riderTokenNew, {
          statuses: typeNormalStatusesDeliveryRider,
          type: DELIVERY_TYPE_NORMAL,
          allocatedType: ALLOCATION_MATCH_TYPES.DELIVERY,
        }),
      );
    }

    // type=2, 배송건
    if (typePickupStatusesDeliveryRider.length) {
      promises.push(
        api.get("deliveries/v2", riderTokenNew, {
          statuses: typePickupStatusesDeliveryRider,
          type: DELIVERY_TYPE_PICKUP,
          allocatedType: ALLOCATION_MATCH_TYPES.DELIVERY,
        }),
      );
    }

    // type=3, 배송건
    if (typeReturnStatusesDeliveryRider.length) {
      promises.push(
        api.get("deliveries/v2", riderTokenNew, {
          statuses: typeReturnStatusesDeliveryRider,
          type: DELIVERY_TYPE_RETURN,
          allocatedType: ALLOCATION_MATCH_TYPES.DELIVERY,
        }),
      );
    }

    const results = await Promise.all(promises);

    const _deliveries = results.flat(1);

    const _deliveriesAllocated = await api.get("deliveries", riderTokenNew, {
      status: STATUS_DELIVERY_ALLOCATED,
    });

    const { rows: _deliveriesCs } = await api.get(`cses/all`, riderTokenNew, {
      checked: false,
      from: 30,
    });

    const deliveriesCs = _deliveriesCs.map((d) => new Cs(d));

    // 테스트용
    // const _deliveriesaa = await api.get('deliveries', riderTokenNew, { status: STATUS_ALL });
    // const diff = _deliveriesaa.filter(x => !_deliveries.some((y) => x.bookId === y.bookId))

    const deliveries = _deliveries.map(
      (d) => new Delivery(d, _deliveriesAllocated),
    );
    const deliveriesAllocated = _deliveriesAllocated.map(
      (d) => new Delivery(d),
    );

    dispatch({
      type: CONSTANTS.FETCH_ALLOCATED,
      deliveriesAllocated,
    });

    if (containCses) {
      setDeliveries(statuses, deliveries, deliveriesCs);
    } else {
      setDeliveries(statuses, deliveries, []);
    }
  }, [setDeliveries]);

  // const fetchAllocated = useCallback(
  //   async () => {
  //     const response = await api.get('deliveries', riderTokenNew, { status: STATUS_DELIVERY_ALLOCATED });
  //     const deliveriesAllocated = response.map(d => new Delivery(d))

  //     dispatch({
  //       type: CONSTANTS.FETCH_ALLOCATED,
  //       deliveriesAllocated,
  //     });
  //   },
  //   [dispatch],
  // );

  const fetchLimitedHoursCount = useCallback(async () => {
    const response = await api.get("deliveries/v2", riderTokenNew, {
      statuses: [DELIVERY_STATUS_TYPES.DELIVERY_STARTED],
      type: DELIVERY_TYPE_NORMAL,
      allocatedType: ALLOCATION_MATCH_TYPES.DELIVERY,
    });

    const deliveries = response.map((d) => new Delivery(d));
    const isLimitedHours = deliveries.filter((e) => !!e.isLimitedHours);

    dispatch({
      type: CONSTANTS.SET_LIMITED_HOURS_COUNT,
      limitedHoursCount: isLimitedHours.length,
    });
  });

  const fetchCount = useCallback(async () => {
    const pickups = await api.get("deliveries/count", riderTokenNew, {
      state: STATUS_PICKUP_FOR_COUNT,
    });
    const pickupsNormal = await api.get("deliveries/count", riderTokenNew, {
      state: STATUS_PICKUP_FOR_COUNT,
      types: DELIVERY_TYPE_NORMAL,
    });
    const pickupsReturn = await api.get("deliveries/count", riderTokenNew, {
      state: STATUS_PICKUP_FOR_COUNT,
      types: DELIVERY_TYPE_PICKUP_RETURN,
    });
    const deliveriesAllocated = await api.get(
      "deliveries/count",
      riderTokenNew,
      { state: STATUS_DELIVERY_ALLOCATED_FOR_COUNT },
    );
    const deliveriesBeforeStart = await api.get(
      "deliveries/count",
      riderTokenNew,
      { state: STATUS_DELIVERY_WAITING_FOR_COUNT },
    );
    const deliveriesPostponed = await api.get(
      "deliveries/count",
      riderTokenNew,
      { state: STATUS_DELIVERY_POSTPONED_FOR_COUNT },
    );
    const deliveriesStarted = await api.get("deliveries/count", riderTokenNew, {
      state: STATUS_DELIVERY_STARTED_FOR_COUNT,
    });
    const delivered = await api.get("deliveries/count", riderTokenNew, {
      state: STATUS_DELIVERED_FOR_COUNT,
    });
    const { rows: cses } = await api.get(`cses/all`, riderTokenNew, {
      checked: false,
      from: 30,
    });

    dispatch({
      type: CONSTANTS.FETCH_COUNT,
      count: {
        pickups: pickups.count,
        pickupsNormal: pickupsNormal.count,
        pickupsReturn: pickupsReturn.count,
        deliveriesAllocated: deliveriesAllocated.count,
        deliveriesBeforeStart: deliveriesBeforeStart.count,
        deliveriesPostponed: deliveriesPostponed.count,
        deliveriesStarted: deliveriesStarted.count,
        delivered: delivered.count,
        cses: cses?.length,
      },
    });
  }, [dispatch]);

  /**
   * 배송 목록(완료건)
   */
  const fetchCompleted = useCallback(async () => {
    const query = {
      status: STATUS_COMPLETED,
      dateFrom: yymmddWithDash(new Date()),
      dateTo: addDays(new Date(), 1),
    };

    const response = await api.get("deliveries", riderTokenNew, query);

    response.sort((a, b) => {
      return new Date(a.deliveryCompletedDate) >
        new Date(b.deliveryCompletedDate)
        ? -1
        : 1;
    });

    const deliveryCompleted =
      response.length > 0 ? new Delivery(response[0]) : null;
    dispatch({
      type: CONSTANTS.FETCH_COMPLETED,
      deliveryCompleted,
    });

    return deliveryCompleted;
  }, [dispatch]);

  /**
   * statuses 변경
   */
  const setStatuses = useCallback(
    async (statuses) => {
      await localStorage.setItem("statuses", JSON.stringify(statuses));

      dispatch({
        type: CONSTANTS.SET_STATUSES,
        statuses,
      });
    },
    [dispatch],
  );

  const resetStatuses = useCallback(async () => {
    await localStorage.setItem(
      "statuses",
      JSON.stringify(INITIAL_STATE.statuses),
    );

    dispatch({
      type: CONSTANTS.SET_STATUSES,
      statuses: INITIAL_STATE.statuses,
    });
  }, [dispatch]);

  /**
   * 배송 대기 변경
   */
  const prevDeliveries = useCallback(
    async ({ bookIds, status }) =>
      await api.put("deliveries/prev", riderTokenNew, { bookIds, status }),
    [],
  );

  /**
   * 배송 연기 변경
   */
  const setPostponed = useCallback(
    async (bookId) =>
      await api.put(`deliveries/${bookId}/postponed`, riderTokenNew),
    [],
  );

  return {
    state,
    fetchAll,
    fetchCount,
    fetchLimitedHoursCount,
    fetchCompleted,
    prevDeliveries,
    setStatuses,
    resetStatuses,
    setPostponed,
  };
};
