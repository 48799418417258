import { addHours } from "date-fns";
import { riderId } from "utils/params";

export const STATUS_ALL = "DELIVERY_PICKUP_BOTH";
export const STATUS_PICKUP = "PICKUP";
export const STATUS_PICKUP_NORMAL = "PICKUP_NORMAL";
export const STATUS_PICKUP_RETURN = "PICKUP_RETURN";
export const STATUS_DELIVERY_WAITING = "DELIVERY_WAITING";
export const STATUS_DELIVERY_POSTPONED = "DELIVERY_POSTPONED";
export const STATUS_DELIVERY_STARTED = "DELIVERY_STARTED";
export const STATUS_DELIVERY_ALLOCATED = "DELIVERY_ALLOCATED";
export const STATUS_COMPLETED = "DELIVERED";
export const STATUS_SENT_BACK = "SENT_BACK";
export const STATUS_LOST = "LOST";
export const STATUS_CS = "CS";
export const STATUS_PICKUP_COMPLETED = "PICKUP_COMPLETED"; // 수거 완료
export const STATUS_ADDRESS_NOT_SUPPORTED = "ADDRESS_NOT_SUPPORTED";

export const DELIVERY_STATUS_TYPES = {
  RECEIPTED: 0,
  PICKUP_SCHEDULED: 1,
  PICKUP_COMPLETED: 2,
  WAREHOUSED: 3,
  DELIVERY_STARTED: 4,
  DELIVERY_COMPLETED: 5,
  DELIVERY_SENT_BACK: 6,
  DELIVERY_LOST: 7,
  DELIVERY_RIDER_ALLOCATED: 8,
  ADDRESS_NOT_SUPPORTED: 9,
  ACCIDENT: 10,
  CANCELED: 11,
  DELIVERY_POSTPONED: 12,
};

export const ALLOCATION_MATCH_TYPES = {
  PICKUP: "1",
  DELIVERY: "2",
};

export const STATUS_PICKUP_FOR_COUNT = "pickup-scheduled";
export const STATUS_DELIVERY_ALLOCATED_FOR_COUNT = "delivery-allocated";
export const STATUS_DELIVERY_WAITING_FOR_COUNT =
  "delivery-allocated-before-start";
export const STATUS_DELIVERY_POSTPONED_FOR_COUNT = "delivery-postponed";
export const STATUS_DELIVERY_STARTED_FOR_COUNT = "delivery-started";
export const STATUS_DELIVERED_FOR_COUNT = "delivered";

export const DELIVERY_TYPE_NORMAL = "1";
export const DELIVERY_TYPE_PICKUP = "2";
export const DELIVERY_TYPE_RETURN = "3";
export const DELIVERY_TYPE_PICKUP_RETURN = "2,3";

export const ADDRESS_API_TYPE_ONE = "SOOJIONE";
export const ADDRESS_API_TYPE_KAKAO = "KAKAO";

export const COPRUSER_CJOYAPI = "cjoyapi";
export const COPRUSER_CJLOGISTICS = "cjlogistics";
export const COPRUSER_HANJINAPI = "hanjinapi";

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function getDistance(lat1, lng1, lat2, lng2) {
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1); // deg2rad below
  let dLon = deg2rad(lng2 - lng1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c; // Distance in km

  return Math.round(d * 1000);
}

export const getPointToArray = (point) => {
  if (point && typeof point === "string" && point.indexOf("POINT") > -1) {
    let pointArray = point.substring(6, point.length - 1).split(" ");
    return pointArray;
  } else if (point && typeof point === "object" && point.coordinates) {
    return point.coordinates;
  } else {
    return [0, 0];
  }
};

export const renderStatusByNumber = (status) => {
  switch (status) {
    case 0:
      return "접수";
    case 1:
      return "수거지정";
    case 2:
      return "수거완료";
    case 3:
      return "입고완료";
    case 4:
      return "배송출발";
    case 5:
      return "배송완료";
    case 6:
      return "반송완료";
    case 7:
      return "분실완료";
    case 8:
      return "배송배차";
    case 9:
      return "배송불가";
    case 10:
      return "사고";
    case 11:
      return "취소";
    case 12:
      return "배송연기";
    default:
      return "";
  }
};

export const stringToObject = (str) => {
  try {
    if (typeof str === "object") {
      return str;
    } else {
      let obj = JSON.parse(str);
      return obj;
    }
  } catch (e) {
    return null;
  }
};

export class Delivery {
  accidentAt = null;
  accidentReason = "";
  addressNotSupported = false;
  bookId = "";
  canceledAt = null;
  canceledReason = "";
  corpUser = null;
  DelayedDeliveries = null;
  deliveryAllocatedDate = null;
  deliveryCompletedDate = null;
  deliveryRiderId = null;
  etc1 = "";
  etc2 = "";
  etc3 = "";
  etc4 = {};
  order = null;
  prepaid = null;
  pickupDateCompleted = null;
  pickupDateScheduled = null;
  pickupRiderId = null;
  productPrice = 0;
  reallocatedRiderId = null;
  reallocationRequestedAt = null;
  receiptDate = null;
  receiverAddress = null;
  receiverAddressDetail = "";
  receiverAddressRoad = null;
  receiverDong = null;
  receiverSmsMobile = null;
  receiverMobile = "";
  receiverMobile2 = "";
  receiverName = null;
  releasedAt = null;
  senderDong = null;
  senderAddress = null;
  senderAddressDetail = "";
  senderAddressRoad = null;
  senderMobile = "";
  senderMobile2 = "";
  senderName = null;
  spot = null;
  status = STATUS_PICKUP;
  statusMarkerOrder = 1;
  time = null;
  warehousedAt = null;
  type = DELIVERY_TYPE_NORMAL;
  unrefinedAddress = null;

  // canceled
  isAccident = false;
  isCanceled = false;

  constructor(delivery, deliveriesAllocated, isCs) {
    this.addressNotSupported = delivery.addressNotSupported;
    this.bookId = delivery.bookId;

    if (isCs) {
      this.status = STATUS_CS;
      this.statusMarkerOrder = 5;
    } else {
      switch (Number(delivery.status)) {
        case 1:
          this.status = STATUS_PICKUP;
          this.statusMarkerOrder = 2;
          break;
        case 2:
          this.status = STATUS_PICKUP_COMPLETED;
          break;
        case 3:
          this.status = STATUS_DELIVERY_WAITING;
          this.statusMarkerOrder = 1;
          break;
        case 4:
          this.status = STATUS_DELIVERY_STARTED;
          this.statusMarkerOrder = 4;
          break;
        case 5:
          this.status = STATUS_COMPLETED;
          break;
        case 6:
          this.status = STATUS_SENT_BACK;
          break;
        case 7:
          this.status = STATUS_LOST;
          break;
        case 8:
          this.status = STATUS_DELIVERY_WAITING;
          this.statusMarkerOrder = 1;
          break;
        case 9:
          this.status = STATUS_ADDRESS_NOT_SUPPORTED;
          break;
        case 12:
          this.status = STATUS_DELIVERY_POSTPONED;
          this.statusMarkerOrder = 3;
          break;

        default:
          throw new Error("잘못된 배송상태값입니다.");
      }
    }

    // dates by status
    this.corpUser = delivery.corpUser;
    this.DelayedDeliveries = delivery.DelayedDeliveries;
    this.deliveryAllocatedDate = delivery.deliveryAllocatedDate;
    this.deliveryCompletedDate = delivery.deliveryCompletedDate;
    this.deliveryRiderId = delivery.deliveryRiderId;
    this.etc1 = delivery.etc1;
    this.etc2 = delivery.etc2;
    this.etc3 = delivery.etc3;
    this.etc4 = stringToObject(delivery.etc4);
    this.memoFromCustomer = delivery.memoFromCustomer;
    this.order = delivery.order;
    this.prepaid = delivery.prepaid;
    this.pickupDateCompleted = delivery.pickupDateCompleted;
    this.pickupRiderId = delivery.pickupRiderId;
    this.pickupDateScheduled = delivery.pickupDateScheduled;
    this.reallocationRequestedAt = delivery.reallocationRequestedAt;
    this.reallocatedRiderId = delivery.reallocatedRiderId;
    this.receiptDate = delivery.receiptDate;
    this.receiverAddress = delivery.receiverAddress;
    this.receiverAddressDetail = delivery.receiverAddressDetail || "";
    this.receiverAddressRoad = delivery.receiverAddressRoad;
    this.receiverDong =
      (delivery.receiverDongObj && delivery.receiverDongObj.name) ||
      delivery.receiverDong;
    this.receiverMobile = delivery.receiverMobile;
    this.receiverMobile2 = delivery.receiverMobile2;
    this.receiverName = delivery.receiverName;
    this.receiverPoint = delivery.receiverPoint;
    this.receiverSmsMobile = delivery.receiverSmsMobile;
    this.releasedAt = delivery.releasedAt;
    this.productPrice = delivery.productPrice;
    this.senderAddress = delivery.senderAddress;
    this.senderAddressDetail = delivery.senderAddressDetail || "";
    this.senderAddressRoad = delivery.senderAddressRoad;
    this.senderDong =
      (delivery.senderDongObj && delivery.senderDongObj.name) ||
      delivery.senderDong;
    this.senderMobile = delivery.senderMobile;
    this.senderMobile2 = delivery.senderMobile2;
    this.senderName = delivery.senderName;
    this.senderPoint = delivery.senderPoint;
    this.spot = delivery.spot || null;
    this.time = delivery.time || null;
    this.type = delivery.type || DELIVERY_TYPE_NORMAL;
    this.warehousedAt = delivery.warehousedAt;
    this.unrefinedAddress = delivery.unrefinedAddress;

    // canceled
    this.isAccident = !!delivery.isAccident;
    this.accidentAt = delivery.accidentAt || null;
    this.accidentReason = delivery.accidentReason || null;
    this.isCanceled = !!delivery.isCanceled;
    this.canceledAt = delivery.canceledAt || null;
    this.canceledReason = delivery.canceledReason || null;

    this.deliveriesAllocated = deliveriesAllocated;
  }

  get addressApiType() {
    return this.etc4 ? this.etc4.addressApiType : "";
  }

  get compareSenderLocation() {
    return {
      longitude: getPointToArray(this.senderPoint)[0],
      latitude: getPointToArray(this.senderPoint)[1],
    };
  }
  get compareReceiverLocation() {
    return {
      longitude: getPointToArray(this.receiverPoint)[0],
      latitude: getPointToArray(this.receiverPoint)[1],
    };
  }

  get customerAddress() {
    return this.etc4 ? this.etc4.customerAddress : null;
  }

  get customerAddressDetail() {
    return this.etc4 ? this.etc4.customerAddressDetail : "";
  }

  get customerAddressEng() {
    return this.etc4 ? this.etc4.customerAddressEng : "";
  }

  get customerAddressEngGpt() {
    return this.etc4 ? this.etc4.customerAddressEngGpt : "";
  }

  get deliveryPlace() {
    return this.etc4 ? this.etc4.deliveryPlace : "";
  }

  get displayAddress() {
    if (this.unrefinedAddress) {
      return this.unrefinedAddress;
    } else if (this.customerAddress) {
      return `${this.customerAddress}${
        this.customerAddressDetail &&
        this.customerAddressDetail !== this.customerAddress
          ? `, ${this.customerAddressDetail}`
          : ""
      }`;
    } else {
      return `${this.receiverAddress || this.receiverAddressRoad || ""}${
        this.receiverAddressDetail ? `, ${this.receiverAddressDetail}` : ""
      }`;
    }
  }

  get distanceRefineToLimitedRefined() {
    if (this.soojioneLimitedCustomized?.point && this.receiverPoint) {
      return getDistance(
        Number(this.compareReceiverLocation.latitude),
        Number(this.compareReceiverLocation.longitude),
        Number(this.soojioneLimitedCustomizedLngLat.latitude),
        Number(this.soojioneLimitedCustomizedLngLat.longitude),
      );
    } else {
      return null;
    }
  }

  get isHighPrice() {
    return (
      (this.status === STATUS_DELIVERY_WAITING ||
        this.status === STATUS_DELIVERY_POSTPONED ||
        this.status === STATUS_DELIVERY_STARTED ||
        this.statusAddressNotSupported === STATUS_DELIVERY_STARTED) &&
      (this.productPrice >= 200000 || this.spotLuxuryAvailable)
    );
  }

  get isLimitedHours() {
    return (
      (this.status === STATUS_DELIVERY_WAITING ||
        this.status === STATUS_DELIVERY_POSTPONED ||
        this.status === STATUS_DELIVERY_STARTED ||
        this.statusAddressNotSupported === STATUS_DELIVERY_STARTED) &&
      this.limitedDeliveryHours > 0
    );
  }

  get isPenaltyCorpUser() {
    return (
      this.corpUser?.username === COPRUSER_CJOYAPI ||
      this.corpUser?.username === COPRUSER_CJLOGISTICS ||
      this.corpUser?.username === COPRUSER_HANJINAPI
    );
  }

  get keyColor() {
    switch (this.status) {
      case STATUS_PICKUP:
        return "orange";
      case STATUS_DELIVERY_WAITING:
      case STATUS_DELIVERY_POSTPONED:
      case STATUS_DELIVERY_STARTED:
        if (this.limitedDeliveryHours > 0) {
          return "limitedDeliveryHours";
        } else if (this.productPrice >= 200000 || this.spotLuxuryAvailable) {
          return "highPrice";
        } else {
          return "mint";
        }
      case STATUS_COMPLETED:
      case STATUS_SENT_BACK:
      case STATUS_LOST:
        return "yellow";
      case STATUS_CS:
        return "black";
      case STATUS_ADDRESS_NOT_SUPPORTED:
        if (this.statusAddressNotSupported === STATUS_DELIVERY_STARTED) {
          if (this.limitedDeliveryHours > 0) {
            return "limitedDeliveryHours";
          } else if (this.productPrice >= 200000 || this.spotLuxuryAvailable) {
            return "highPrice";
          } else {
            return "mint";
          }
        } else {
          return "orange";
        }
      default:
        return "orange";
    }
  }

  get keyColorHex() {
    switch (this.status) {
      case STATUS_PICKUP:
        return "#ea2845";
      case STATUS_ADDRESS_NOT_SUPPORTED:
        if (this.statusAddressNotSupported === STATUS_DELIVERY_STARTED) {
          return "#66cd46";
        } else {
          return "#ea2845";
        }
      default:
        return "#66cd46";
    }
  }

  get keyColorHexNew() {
    switch (this.status) {
      case STATUS_PICKUP:
        if (this.type === DELIVERY_TYPE_NORMAL) {
          return "#FC3F00";
        } else {
          return "#202020";
        }
      case STATUS_DELIVERY_WAITING:
        return "#FFA800";
      case STATUS_DELIVERY_POSTPONED:
        return "#4350B6";
      case STATUS_DELIVERY_STARTED:
        return "#14B6C1";
      case STATUS_CS:
        return "#000000";
      case STATUS_ADDRESS_NOT_SUPPORTED:
        if (this.statusAddressNotSupported === STATUS_DELIVERY_STARTED) {
          return "#14B6C1";
        } else {
          if (this.type === DELIVERY_TYPE_NORMAL) {
            return "#FC3F00";
          } else {
            return "#202020";
          }
        }
      default:
        return "#14B6C1";
    }
  }

  get limitedDeliveryHours() {
    return this.spot
      ? this.spot.limitedDeliveryHours
      : this.etc4
      ? this.etc4.limitedDeliveryHours
      : null;
  }

  get limitedDeliveryAt() {
    return this.pickupDateCompleted && this.limitedDeliveryHours > 0
      ? addHours(new Date(this.pickupDateCompleted), this.limitedDeliveryHours)
      : null;
  }

  get mustSignature() {
    return this.etc4 ? this.etc4.mustSignature : false;
  }

  get orderingNumberByDeliveryRiderId() {
    return (this.status === STATUS_DELIVERY_STARTED ||
      this.statusAddressNotSupported === STATUS_DELIVERY_STARTED) &&
      this.etc4?.orderingNumberByDeliveryRiderId
      ? this.etc4?.orderingNumberByDeliveryRiderId
      : null;
  }

  get reallocated() {
    return this.etc4 && this.etc4.reallocated;
  }

  get receiverSigungu() {
    if (this.receiverDongObj && this.receiverDongObj.sigungu) {
      return this.receiverDongObj.sigungu;
    } else if (
      this.receiverDongObj &&
      this.receiverAddress &&
      this.receiverDongObj.name
    ) {
      return this.receiverAddress.split(this.receiverDongObj.name)[0];
    } else if (this.receiverAddress && this.receiverDong) {
      return this.receiverAddress.split(this.receiverDong)[0];
    } else {
      return "";
    }
  }

  get receiverAddressObj() {
    return this.etc4 ? this.etc4.receiverAddressObj : null;
  }

  get receiverAddressRoadWithoutSigungu() {
    if (this.receiverAddressRoad && this.receiverSigungu) {
      return this.receiverAddressRoad.split(this.receiverSigungu)[
        this.receiverAddressRoad.split(this.receiverSigungu).length - 1
      ];
    } else if (this.receiverAddressRoad) {
      return this.receiverAddressRoad.split(" ").slice(2).join(" ");
    } else {
      return "";
    }
  }

  get receiverAddressWithoutSigungu() {
    if (this.receiverAddress && this.receiverSigungu) {
      return this.receiverAddress.split(this.receiverSigungu)[
        this.receiverAddress.split(this.receiverSigungu).length - 1
      ];
    } else if (this.receiverAddress) {
      return this.receiverAddress.split(" ").slice(2).join(" ");
    } else {
      return "";
    }
  }

  get receiverBuilding() {
    if (this.etc4 && this.etc4.receiverBuilding) {
      return this.etc4.receiverBuilding;
    } else if (this.receiverAddressObj && this.receiverAddressObj.building) {
      return this.receiverAddressObj.building;
    } else {
      return null;
    }
  }

  get receiverBuildingDongNumber() {
    if (this.receiverAddressObj && this.receiverAddressObj.buildingDongNumber) {
      return this.receiverAddressObj.buildingDongNumber;
    } else {
      return null;
    }
  }

  get refinedAddressRawResult() {
    return this.etc4?.refinedAddressRawResult;
  }

  get refinedAddressRawResultError() {
    return (
      this.refinedAddressRawResult?.RCD3 === "D" ||
      this.refinedAddressRawResult?.RCD3 === "E"
    );
  }

  get displayReceiverBuildingDongNumber() {
    if (this.receiverAddressObj && this.receiverAddressObj.buildingDongNumber) {
      return `${this.receiverAddressObj.buildingDongNumber}동`;
    } else {
      return null;
    }
  }

  get renderAddressApiType() {
    if (this.addressApiType === "TMAP") {
      return "티맵";
    } else if (this.addressApiType === "KAKAO") {
      return "카카오";
    } else if (this.addressApiType === "SOOJIONE") {
      return "수지원";
    } else {
      return "";
    }
  }

  get renderReceiverName() {
    let name = this.receiverName || "";

    if (this.type === DELIVERY_TYPE_NORMAL) {
      if (name?.length > 2) {
        return name.charAt(0) + "*" + name.slice(2);
      } else {
        return name.charAt(0) + "*";
      }
    } else {
      return name;
    }
  }

  get renderSenderName() {
    let name = this.senderName || "";

    if (this.type === DELIVERY_TYPE_NORMAL) {
      return name;
    } else {
      if (name?.length > 2) {
        return name.charAt(0) + "*" + name.slice(2);
      } else {
        return name.charAt(0) + "*";
      }
    }
  }

  // 동일한 수하인(수하인이름, 주소)인 배송 목록
  get sameReceiverDeliveriesObj() {
    let wating = [];
    let started = [];
    let postponed = [];

    if (
      (this.status === STATUS_DELIVERY_WAITING ||
        this.status === STATUS_DELIVERY_POSTPONED ||
        this.status === STATUS_DELIVERY_STARTED ||
        this.statusAddressNotSupported === STATUS_DELIVERY_STARTED) &&
      this.deliveriesAllocated &&
      this.deliveriesAllocated.length &&
      this.type === DELIVERY_TYPE_NORMAL
    ) {
      this.deliveriesAllocated.forEach((d) => {
        if (
          this.bookId !== d.bookId &&
          this.type === d.type &&
          this.receiverName === d.receiverName &&
          this.receiverMobile === d.receiverMobile &&
          this.receiverAddress === d.receiverAddress
        ) {
          if (d.status === 3 || d.status === 8) {
            wating.push(d);
          } else if (d.status === 4) {
            started.push(d);
          } else if (d.status === 12) {
            postponed.push(d);
          }
        }
      });
    }

    return {
      wating,
      started,
      postponed,
    };
  }

  get senderSigungu() {
    if (this.senderDongObj && this.senderDongObj.sigungu) {
      return this.senderDongObj.sigungu;
    } else if (
      this.senderDongObj &&
      this.senderAddress &&
      this.senderDongObj.name
    ) {
      return this.senderAddress.split(this.senderDongObj.name)[0];
    } else if (this.senderAddress && this.senderDong) {
      return this.senderAddress.split(this.senderDong)[0];
    } else {
      return "";
    }
  }

  get senderAddressObj() {
    return this.etc4 ? this.etc4.senderAddressObj : null;
  }

  get senderBuilding() {
    if (this.etc4 && this.etc4.senderBuilding) {
      return this.etc4.senderBuilding;
    } else if (this.senderAddressObj && this.senderAddressObj.building) {
      return this.senderAddressObj.building;
    } else {
      return null;
    }
  }

  get senderBuildingDongNumber() {
    if (this.senderAddressObj && this.senderAddressObj.buildingDongNumber) {
      return `${this.senderAddressObj.buildingDongNumber}동`;
    } else {
      return null;
    }
  }

  get displaySenderBuildingDongNumber() {
    if (this.senderAddressObj && this.senderAddressObj.buildingDongNumber) {
      return this.senderAddressObj.buildingDongNumber;
    } else {
      return null;
    }
  }

  get senderAddressRoadWithoutSigungu() {
    if (this.senderAddressRoad && this.senderSigungu) {
      return this.senderAddressRoad.split(this.senderSigungu)[
        this.senderAddressRoad.split(this.senderSigungu).length - 1
      ];
    } else if (this.senderAddressRoad) {
      return this.senderAddressRoad.split(" ").slice(2).join(" ");
    } else {
      return this.senderAddressRoad;
    }
  }

  get senderAddressWithoutSigungu() {
    if (this.senderAddress && this.senderSigungu) {
      return this.senderAddress.split(this.senderSigungu)[
        this.senderAddress.split(this.senderSigungu).length - 1
      ];
    } else if (this.senderAddress) {
      return this.senderAddress.split(" ").slice(2).join(" ");
    } else {
      return this.senderAddress;
    }
  }

  get soojioneLimitedCustomized() {
    return this.etc4 ? this.etc4.soojioneLimitedCustomized : null;
  }

  get soojioneLimitedCustomizedLngLat() {
    if (this.soojioneLimitedCustomized) {
      return {
        longitude: getPointToArray(this.soojioneLimitedCustomized?.point)[0],
        latitude: getPointToArray(this.soojioneLimitedCustomized?.point)[1],
      };
    } else {
      return null;
    }
  }

  get soojioneLimitedCustomizedAddressRoad() {
    return this.soojioneLimitedCustomized
      ? this.soojioneLimitedCustomized.addressRoad
      : null;
  }

  get soojioneLimitedCustomizedSigungu() {
    return this.soojioneLimitedCustomized
      ? this.soojioneLimitedCustomized.sigungu
      : null;
  }

  get soojioneLimitedCustomizedAddressRoadWithoutSigungu() {
    if (
      this.soojioneLimitedCustomizedAddressRoad &&
      this.soojioneLimitedCustomizedSigungu
    ) {
      return this.soojioneLimitedCustomizedAddressRoad.split(
        this.soojioneLimitedCustomizedSigungu,
      )[
        this.soojioneLimitedCustomizedAddressRoad.split(
          this.soojioneLimitedCustomizedSigungu,
        ).length - 1
      ];
    } else if (this.soojioneLimitedCustomizedAddressRoad) {
      return this.soojioneLimitedCustomizedAddressRoad
        .split(" ")
        .slice(2)
        .join(" ");
    } else {
      return "";
    }
  }

  get spotName() {
    return this.spot ? this.spot.name : this.senderName;
  }

  get spotEtcObj() {
    return this.spot?.etc ? JSON.parse(this.spot?.etc) : {};
  }

  get spotKurlyDrink() {
    return !!this.spotEtcObj?.kurlyDrink;
  }

  get spotLuxuryAvailable() {
    return !!this.spotEtcObj?.luxuryAvailable;
  }

  get statusAddressNotSupported() {
    if (
      this.addressNotSupported ||
      this.status === STATUS_ADDRESS_NOT_SUPPORTED
    ) {
      if (this.deliveryRiderId === Number(riderId)) {
        return STATUS_DELIVERY_STARTED;
      } else if (this.pickupRiderId === Number(riderId)) {
        return STATUS_PICKUP;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  get statusString() {
    if (this.addressNotSupported) {
      return "배송불가";
    } else {
      switch (this.status) {
        case STATUS_PICKUP:
          return "미수거";
        case STATUS_DELIVERY_WAITING:
          return "대기";
        case STATUS_DELIVERY_POSTPONED:
          return "연기";
        case STATUS_DELIVERY_STARTED:
          return "배송";
        case STATUS_COMPLETED:
          return "배송완료";
        case STATUS_SENT_BACK:
          return "반송완료";
        case STATUS_LOST:
          return "분실완료";
        case STATUS_CS:
          return "CS";
        case STATUS_ADDRESS_NOT_SUPPORTED:
          return "배송불가";
        default:
          return "";
      }
    }
  }

  get statusRearrangeKey() {
    switch (this.status) {
      case STATUS_PICKUP:
        return "1";
      case STATUS_DELIVERY_WAITING:
      case STATUS_DELIVERY_POSTPONED:
      case STATUS_DELIVERY_STARTED:
        return "2";
      case STATUS_ADDRESS_NOT_SUPPORTED:
        if (this.statusAddressNotSupported === STATUS_PICKUP) {
          return "1";
        } else if (this.statusAddressNotSupported === STATUS_DELIVERY_STARTED) {
          return "2";
        } else {
          return "";
        }
      default:
        return "";
    }
  }

  get frontdoorPassword() {
    if (this.frontdoorPasswordByClient) {
      return `${this.frontdoorPasswordByClient} ${
        this.frontdoorPasswordByCorp ? `/ ${this.frontdoorPasswordByCorp}` : ""
      }`;
    } else {
      return this.frontdoorPasswordByCorp || null;
    }
  }

  get frontdoorPasswordByClient() {
    return this.etc4 ? this.etc4.frontdoorPasswordByClient : "";
  }

  get frontdoorPasswordByCorp() {
    return this.etc4 ? this.etc4.frontdoorPasswordByCorp : "";
  }

  get typeString() {
    switch (this.type) {
      case DELIVERY_TYPE_PICKUP:
        return "(픽)";
      case DELIVERY_TYPE_RETURN:
        return "(반)";
      default:
        return "";
    }
  }
}
