import React, { useEffect, useCallback } from "react";
import styled from "styled-components";

const PostcodeWrap = styled.div`
  width: 100%;
  height: 100vh;
`;

function load(url, cb, err) {
  let element = document.createElement("script");

  element.async = true;
  element.onload = cb;
  element.onerror = err;

  element["src"] = url;
  document["head"].appendChild(element);
}

function Postcode() {
  const handleCompleteDaumPostcode = useCallback((data) => {
    let roadAddress = data.roadAddress; // 도로명주소
    let jibunAddress = data.jibunAddress || data.autoJibunAddress; // 지번주소
    let extraAddress = ""; // 참고항목 변수
    let zonecode = data.zonecode;

    // 법정동명이 있을 경우 추가한다. (법정리는 제외)
    // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
    if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
      extraAddress += data.bname;
    }
    // 건물명이 있고, 공동주택일 경우 추가한다.
    if (data.buildingName !== "" && data.apartment === "Y") {
      extraAddress +=
        extraAddress !== "" ? ", " + data.buildingName : data.buildingName;
    }
    // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
    if (extraAddress !== "") {
      extraAddress = " (" + extraAddress + ")";
    }

    const addressResult = {
      roadAddress: roadAddress + extraAddress,
      jibunAddress,
      zonecode,
    };

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(addressResult));
    }
  }, []);

  const drawPostcode = useCallback(() => {
    const element_wrap = document.getElementById("wrap");

    new window.daum.Postcode({
      oncomplete: handleCompleteDaumPostcode,
      width: "100%",
      height: "100%",
    }).embed(element_wrap, { autoClose: false });
  }, [handleCompleteDaumPostcode]);

  useEffect(() => {
    load(
      "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js",
      drawPostcode
    );
  }, [drawPostcode]);

  return <PostcodeWrap id="wrap" />;
}

export default Postcode;
